import React from "react"
import Img from "gatsby-image"
import styled from "styled-components"
import { Media } from "../../../media"

const ImageWrapper = styled.div`
  height: 35rem;
  width: 100vw;
`

const FullWidthView = ({ marginBottom, fluidImg }) => {

  const imgStyle = {
    height: "35rem",
    width: "100%",
    objectFit: "initial",
    objectPosition: "center",
  }
  return (
    <Media greaterThanOrEqual="md">
      <ImageWrapper style={{ marginBottom: marginBottom ? marginBottom : 0 }}>
        <Img fluid={fluidImg} style={imgStyle} imgStyle={imgStyle} />
      </ImageWrapper>
    </Media>
  )
}
export default FullWidthView

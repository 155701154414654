import React from "react"
import BackgroundImage from "gatsby-background-image"
import { DEFAULT_BLUE } from "../../../styles/common"
import "./hero.css"
import styled from "styled-components"

const Wrapper = styled.div`
  width: 100vw;
  height: 70vh;
  display: flex;
`

const HeroFooter = styled.div`
  width: 100vw;
  min-height: 30rem;
  display: flex;
  width: 100vw;
  align-self: flex-end;
  flex-direction: column;
  align-items: center;
  color: ${DEFAULT_BLUE};
  text-align: center;
  padding-top: 5rem;
`

const Hero = ({ fluidImg, children, moving = false }) => {
  return (
    <>
      <div className='hero-animation-wrapper'>
        <div className={moving ? 'hero-animation' : ''}>
          <BackgroundImage fluid={fluidImg}>
            <Wrapper></Wrapper>
          </BackgroundImage>
        </div>
      </div>
      <HeroFooter className='background'>{children}</HeroFooter>
    </>
  )
}

export default Hero

import React from "react"
import CollapsibleInformation from "../collapsible-information"
import Img from "gatsby-image"
import { DEFAULT_MAX_WIDTH, DEFAULT_BLUE } from "../../../styles/common"
import { Paper } from "@material-ui/core"
import "./articles-section.css"

const ArticlesSection = ({ articles, fluidImg, leftWidth = 6 }) => {
  const wrapperStyle = {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: DEFAULT_BLUE,
    marginBottom: '10rem'
  }
  const innerWrapperStyle = {
    width: DEFAULT_MAX_WIDTH,
    maxWidth: '100vw',
    overflow: "hidden",
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, 300px)',
    justifyContent: 'space-evenly',
    paddingTop: "10rem",
    paddingBottom: "10rem"
  }
  return (
    <div style={wrapperStyle} className="articles">
      <div style={innerWrapperStyle}>
          {articles.map(article => (
            <CollapsibleInformation
              width={leftWidth}
              title={article.title}
              information={article.information}
            />
          ))}
        {/*<div style={rightPanelStyle}>
          <div style={imgWrapperStyle}>
            <Paper elevation={4}>
              <Img fluid={fluidImg} />
            </Paper>
          </div>
        </div>*/}
      </div>
    </div>
  )
}

export default ArticlesSection

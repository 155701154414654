import React from "react"
import HoverLine from "../../common/hover-line"
import { RichText } from "prismic-reactjs"
import styled from "styled-components"
import "./collapsible.css"

const Panel = styled.div`
  margin-bottom: 0.5rem;
  color: white;
  margin: 1rem;
`
const PanelDetails = styled.div`
  padding-top: 1rem;
  width: 30rem;
`

const CollapsibleInformation = ({ title, information, width }) => {
  return (
    <>
      <Panel>
        <div style={{width: 'fit-content'}}>
          <h2 style={{paddingRight: '1rem', fontSize: '2.3rem'}}>{RichText.asText(title)}</h2>
          <HoverLine show size='0.1' bottom />
        </div>
        <PanelDetails className="collapsible">{RichText.render(information)}</PanelDetails>
      </Panel>
    </>
  )
}

export default CollapsibleInformation

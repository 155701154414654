import React from "react"
import { DEFAULT_BLUE } from "../../../styles/common"
import HoverLine from "../hover-line/dist"
import Hero from "../hero"
import { RichText } from "prismic-reactjs"

const DefaultHero = ({ heroImg, heroTitle, heroInformation, moving = false }) => {
  return (
    <Hero fluidImg={heroImg} moving={moving}>
      <div>
        <h1>{RichText.asText(heroTitle)}</h1>
        <HoverLine show color={DEFAULT_BLUE} size='0.1' />
      </div>
      <div className='hero'>{RichText.render(heroInformation)}</div>
    </Hero>
  )
}

export default DefaultHero
